import React from 'react'
import Layout from '@components/Layout'
import BusinessCard from '@components/BusinessCard'
import JumpCategory from '@components/JumpCategory'
import { Seo } from '@components/Seo'
import { Link, graphql } from 'gatsby'

import PageTitle from '@components/PageTitle'
import { TitleParagraph } from '@global'
import { TagBox, Nextlink, Prevlink, Pager, PagerSegment } from './styles'

const NavLink = ({ url, rel, text }) => (
  <TagBox>
    <Link to={'/businesses/' + url} rel={rel}>
      {text}
    </Link>
  </TagBox>
)

const BusinessIndexTemplate = ({ data, pageContext }) => {
  const jumpCategoryData = data.mycats.edges
  const group = data.allDatoCmsBusiness.edges

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <Seo title="Sowerby Bridge Business Directory in Calderdale West Yorkshire" />
      <PageTitle title="Businesses" />
      <TitleParagraph>Welcome to the Sowerby Bridge Business Directory. If your business is not listed please contact us</TitleParagraph>
      <JumpCategory jumpCategoryData={jumpCategoryData} />

      <Pager className="sc-Pager">
        <PagerSegment className="sc-PagerSegment">
          <Prevlink className="sc-Prevlink">{!isFirst && <NavLink className="sc-NavLink" url={prevPage} text="Previous Page" rel="prev" />}</Prevlink>
        </PagerSegment>
        <PagerSegment className="sc-PagerSegment">
          <Nextlink className="sc-Nextlink">{!isLast && <NavLink className="sc-NavLink" url={nextPage} text="Next Page" rel="next" />}</Nextlink>
        </PagerSegment>
      </Pager>

      <BusinessCard cardData={group} />
      <Pager className="sc-Pager">
        <PagerSegment className="sc-PagerSegment">
          <Prevlink className="sc-Prevlink">{!isFirst && <NavLink className="sc-NavLink" url={prevPage} text="Previous Page" rel="prev" />}</Prevlink>
        </PagerSegment>
        <PagerSegment className="sc-PagerSegment">
          <Nextlink className="sc-Nextlink">{!isLast && <NavLink className="sc-NavLink" url={nextPage} text="Next Page" rel="next" />}</Nextlink>
        </PagerSegment>
      </Pager>
    </Layout>
  )
}

export const mygraphgraph = graphql`
  query categoryquery($skip: Int!, $limit: Int!) {
    mycats: allDatoCmsBusinessType(sort: { fields: category, order: ASC }) {
      edges {
        node {
          id
          category
          slug
        }
      }
    }
    allDatoCmsBusiness(sort: { order: ASC, fields: title }, limit: $limit, skip: $skip) {
      edges {
        node {
          title
          slug
          logo {
            gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: TRACED_SVG)
          }
          shortDescription
        }
      }
    }
  }
`

export default BusinessIndexTemplate
