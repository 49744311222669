import styled from 'styled-components'
import { colours } from '@variables'

export const TagBox = styled.div`
  display: inline-block;
  background-color: ${colours.pgwarmgrey};
  color: ${colours.pgwhite};
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  &:hover {
    background-color: ${colours.pggreen};
  }

  a {
    display: block;
    color: ${colours.pgwhite};
    padding: 0.5em;
    &:hover {
      background-color: ${colours.pggreen};
      color: ${colours.pgwhite};
    }
    &:visited {
      color: ${colours.pgwhite};
    }
    &:active {
      color: ${colours.pgwhite};
      background-color: ${colours.pgwarmgrey};
    }
  }
`

export const Nextlink = styled.div`
  float: right;
`

export const Prevlink = styled.div`
  float: left;
`

export const Pager = styled.div`
  display: flex;
  margin-top: 0;
`

export const PagerSegment = styled.div`
  margin-bottom: 0.5rem;
  flex: 1;
`
